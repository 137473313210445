/* eslint-disable react/no-array-index-key */
import { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';

export type InputProps = {
  className?: string,
  label?: string,
  startIcon?: React.ReactNode,
  endIcons?: React.ReactNode[],
  error?: string,
  useErrorLabel?: boolean,
  size?: 'md' | 'lg',
} & Omit<React.ComponentPropsWithoutRef<'input'>, 'size'>;

const Input = ({
  className,
  label,
  startIcon,
  endIcons,
  disabled,
  error,
  useErrorLabel = false,
  size = 'md',
  ...props
}: InputProps) => {
  const wrapperKlasses = twMerge(
    'relative flex w-full border-2 focus-within:ring-4 gap-2 items-center bg-white',
    size === 'md' ? 'rounded-3xl' : 'rounded-[40px]',
    disabled ? 'bg-mauve-10 border-mauve-10' : 'border-teal-60 ring-teal-20',
    error ? 'ring-rose-60 border-rose-100' : '',
    className,
  );

  const inputKlasses = twMerge(
    'w-full outline-none text-blue-100 text-base font-museo-300 disabled:bg-mauve-10 mr-4 ml-4',
    size === 'md' ? 'my-3' : 'my-5',
    !!startIcon && 'ml-0',
    !!endIcons?.length && 'mr-0',
  );

  return (
    <div className="grid gap-2">
      {!!label && <span className="text-blue-100 font-museo-700">{label}</span>}

      <div className={wrapperKlasses}>
        {startIcon && startIcon}
        <input {...props} className={inputKlasses} disabled={disabled} />
        {endIcons && endIcons.map((icon, index) => (
          <Fragment key={index}>{icon}</Fragment>))}
      </div>

      {!!error && useErrorLabel && (
      <span className="text-sm text-rose-400 font-museo-500 inline-block">
        {error}
      </span>
      )}
    </div>
  );
};

export default Input;
