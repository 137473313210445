/* eslint-disable react/button-has-type */
import { twMerge } from 'tailwind-merge';

export type Sizes = 'sm' | 'md' | 'lg' | 'xl';
type Variants = 'solid' | 'outline' | 'embedded';

export type IconButtonProps = {
  variant?: Variants,
  icon: React.ReactNode,
  size?: Sizes,
} & React.ComponentProps<'button'>;

const IconButton = ({
  className, icon, size = 'sm', variant = 'solid', ...rest
}: IconButtonProps) => {
  const defaultClasses = 'grid place-content-center rounded-full';

  const sizeClasses = {
    sm: 'min-w-[44px] min-h-[44px] w-11 h-11',
    md: 'min-w-[48px] min-h-[48px] w-12 h-12',
    lg: 'min-w-[56px] min-h-[56px] w-14 h-14',
    xl: 'min-w-[64px] min-h-[64px] w-16 h-16',
  };

  const solidVariant = {
    base: 'bg-teal-10',
    hover: 'hover:bg-teal-30',
    focus: 'focus-visible:outline-teal-30 focus:outline-teal-30',
    active: 'active:bg-teal-30 active:border-2 active:border-teal-60',
    disabled: 'disabled:bg-grey-200 disabled:border-grey-200 [&>div>svg]:disabled:fill-grey-400',
  };

  const outlineVariant = {
    base: 'border-2 border-grey-100',
    hover: '[&>div>svg]:hover:fill-green-100',
    focus: 'focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-offset-[-0.2px] focus-visible:outline-teal-30',
    active: 'active:bg-green-100 [&>div>svg]:active:fill-blue-100',
    disabled: 'disabled:active:bg-transparent [&>div>svg]:disabled:fill-grey-400',
  };

  const embeddedVariant = {
    base: '',
    hover: 'hover:bg-teal-20',
    focus: 'focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-offset-[-0.2px] focus-visible:outline-teal-30',
    active: 'active:bg-teal-20',
    disabled: 'disabled:bg-grey-200 disabled:active:bg-grey-200 [&>div>svg]:disabled:fill-grey-400',
  };

  const variants = {
    solid: solidVariant,
    outline: outlineVariant,
    embedded: embeddedVariant,
  };

  const variantClasses = variants[variant];

  const klasses = twMerge(
    defaultClasses,
    sizeClasses[size],
    variantClasses.base,
    variantClasses.hover,
    variantClasses.focus,
    variantClasses.active,
    variantClasses.disabled,
    className,
  );

  return (
    <button
      className={klasses}
      type="button"
      {...rest}
    >
      {icon}
    </button>
  );
};

export default IconButton;
