import {
  NotFoundError, BadRequestError, ForbiddenError, InternalServerError,
} from 'common/lib/errors';

const fetchToCustomErrors = async (response: Response) => {
  const errorBody = await response.json();
  const { message } = errorBody;
  switch (response.status) {
    case 404:
      return new NotFoundError(message);
    case 400:
      return new BadRequestError(message);
    case 403:
      return new ForbiddenError(message);
    default:
      return new InternalServerError(message);
  }
};

export const post = async <T, K>(url: string, body: K, options?: RequestInit): Promise<T> => {
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
    ...options,
  });

  if (!response.ok) {
    const error = await fetchToCustomErrors(response);
    console.log(`POST ${url} \nServer responded with code: ${error.httpCode} | ${error.message}`);
    throw error;
  }

  return response.json();
};

export const get = async <T>(
  url: string,
  query?: Record<string, string>,
  options?: RequestInit,
): Promise<T> => {
  const params = new URLSearchParams(query).toString();

  const response = await fetch(params ? `${url}?${params}` : url, {
    method: 'GET',
    ...options,
  });

  if (!response.ok) {
    const error = await fetchToCustomErrors(response);
    console.log(`GET ${url} \nServer responded with code: ${error.httpCode} | ${error.message}`);
    throw error;
  }

  return response.json();
};

export const patch = async <T, K>(url: string, body: K, options?: RequestInit): Promise<T> => {
  const response = await fetch(url, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
    ...options,
  });

  if (!response.ok) {
    const error = await fetchToCustomErrors(response);
    console.log(`PATCH ${url} \nServer responded with code: ${error.httpCode} | ${error.message}`);
    throw error;
  }

  return response.json();
};

export const put = async <T, K>(url: string, body: K, options?: RequestInit): Promise<T> => {
  const response = await fetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
    ...options,
  });

  if (!response.ok) {
    const error = await fetchToCustomErrors(response);
    console.log(`PATCH ${url} \nServer responded with code: ${error.httpCode} | ${error.message}`);
    throw error;
  }

  return response.json();
};

export const deleteResource = async (
  url: string,
  body: { [key: string]: string },
  options?: RequestInit,
): Promise<void> => {
  const response = await fetch(url, {
    method: 'DELETE',
    body: JSON.stringify(body),
    ...options,
  });

  if (!response.ok) {
    const error = await fetchToCustomErrors(response);
    console.log(`DELETE ${url} \nServer responded with code: ${error.httpCode} | ${error.message}`);
    throw error;
  }
};
