import React, { useState } from 'react';
import * as Yup from 'yup';
import { ObjectSchema } from 'yup';

type ErrorItem = {
  prop_name: string,
  error_message: string,
};

type FormValues = { [key: string]: string | boolean | undefined | Object };

type PropsName<T> = keyof T;

function useForm<T extends FormValues>(
  initialValues: T,
  validationSchema: ObjectSchema<T>,
) {
  const [values, setValues] = useState<T>(initialValues);
  const [errors, setErrors] = useState<ErrorItem[]>([]);
  const [isValid, setIsValid] = useState(false);
  const [dirtyProps, setDirtyProps] = useState<string[]>([]);

  const removeDuplicates = (props: string[]) => (
    props.filter((prop, i) => props.indexOf(prop) === i)
  );

  const getInputValue = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.type === 'checkbox') {
      return event.target.checked;
    }
    return event.target.value;
  };

  const validateForm = async (valuesToValidate: T, newDirtyFields: string[]) => {
    try {
      setDirtyProps(newDirtyFields);
      await validationSchema.validate(valuesToValidate, { abortEarly: false });
      setErrors([]);
      setIsValid(true);
      return true;
    } catch (err) {
      const validationError = err as { inner: Yup.ValidationError[] };
      const formErrors: ErrorItem[] = validationError.inner
        .filter(({ path }) => path && newDirtyFields.includes(path))
        .map((error) => ({
          prop_name: error.path || '',
          error_message: error.message,
        }));
      setErrors(formErrors);
      setIsValid(false);
      return false;
    }
  };

  const handleChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = getInputValue(event);
    const { name } = event.target;

    const newValues = { ...values, [name]: value };
    const newDirtyFields = removeDuplicates([...dirtyProps, name as string]);

    setValues(newValues);
    await validateForm(newValues, newDirtyFields);
  };

  const clearInput = (propName: PropsName<T>) => {
    const newValues = { ...values, [propName]: '' };
    const newDirtyFields = removeDuplicates([...dirtyProps, propName as string]);

    setValues(newValues);
    validateForm(newValues, newDirtyFields);
  };

  const toErrorsObject = (errorsArray: ErrorItem[]) => (
    errorsArray
      .reduce((acc, { prop_name, error_message }) => (
        { ...acc, [prop_name]: error_message }
      ), {} as Record<keyof T, string>)
  );

  const setValue = async (name: PropsName<T>, value: T[PropsName<T>]) => {
    const newValues = { ...values, [name]: value };
    const newDirtyFields = removeDuplicates([...dirtyProps, name as string]);
    setValues(newValues);
    await validateForm(newValues, newDirtyFields);
  };

  return {
    values,
    isValid,
    errors: toErrorsObject(errors),
    handleChange,
    clearInput,
    setValue,
    setValues,
    validateForm,
  };
}

export default useForm;
